<template>
  <div class="languagebox">
    <Headers></Headers>
    <div class=" pt-130px">
    <div
      class="selectbox"
      v-for="(item, index) in lg"
      :key="index"
      :class="{ xuanzhong: changeRed == index }"
      @click="submit(index, item.lg)"
    >
      <div class="box1">{{ item.title }}</div>
      <div class="box2"></div>
    </div>
    </div>
  </div>
</template>

<script>
import Headers from "../components/headers.vue";
export default {
  data() {
    return {
      lg: [
        {
          title: "English",
          lg: "en",
        },
        {
          title: "简体中文",
          lg: "zh",
        },
        {
          title: "繁體中文",
          lg: "zhf",
        },
      ],
      changeRed: 1,
    };
  },
  mounted() {
    const lg = sessionStorage.getItem("Language");
    if (lg) {
      this.$i18n.locale = lg;
    }
    switch (this.$i18n.locale) {
      case "en":
        this.changeRed = 0;
        break;
      case "zh":
        this.changeRed = 1;
        break;
      case "zhf":
        this.changeRed = 2;
        break;
    }
  },
  methods: {
    submit(index, command) {
      this.$i18n.locale = command;
      this.changeRed = index;
      sessionStorage.setItem("Language", command);
    },
  },
  components: {
    Headers,
  },
};
</script>

<style lang="scss" scoped>
.languagebox {
  // color: #333;
  .selectbox {
    height: 40px;
    @apply border-2px border-primary;
    border-radius: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 30px;
    margin-bottom: 25px;
    .box1 {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: bold;
    }
    .box2 {
      width: 20px;
      height: 20px;
      // border: 2px solid rgba($color: #79ccd8, $alpha: 0.6);
      @apply border-2px border-primary;
      border-radius: 15px;
    }
  }
  .xuanzhong {
    .box2 {
      width: 20px;
      height: 20px;
      border: 2px solid rgba($color: #fff, $alpha: 0.6);
      border-radius: 15px;
      @apply bg-primary;
      
    }
  }
}
</style>